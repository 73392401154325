var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("RESUMEN PLAN DE ESTUDIO")]),_c('table',{staticClass:"table"},[_c('thead',{staticStyle:{"background-color":"var(--primary-color)"}},[_c('tr',[_c('th',[_vm._v("NIVEL")]),_vm._l((_vm.time_allocations.filter(
            (x) =>
              x.uses_module_minutes == true &&
              _vm.egress_profile_time_allocations.includes(x.id)
          )),function(hour){return _c('th',{key:hour.id,staticClass:"header-row-rotated"},[_vm._v(" "+_vm._s(hour.name)+" ")])}),_c('th',{staticClass:"header-row-rotated"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.total_pedagogical_hours", true, "Total de Horas Pedagógicas" ))+" ")]),_c('th',{staticClass:"header-row-rotated"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.total_pedagogical_hours_to_chronological", true, "Total de Horas Pedagógicas (En Cronológicas)" ))+" ")]),_vm._l((_vm.time_allocations.filter(
            (x) =>
              x.uses_module_minutes == false &&
              _vm.egress_profile_time_allocations.includes(x.id)
          )),function(hour){return _c('th',{key:hour.id},[_vm._v(" "+_vm._s(hour.name)+" ")])}),(_vm.school && _vm.school.internal_use_id != 'ciisa_uss')?_c('th',{staticClass:"header-row-rotated"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.total_chronological_hours", true, "Total de Horas Cronológicas" ))+" ")]):_vm._e(),(_vm.school && _vm.school.internal_use_id != 'ciisa_uss')?_c('th',{staticClass:"header-row-rotated"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.total_hours_sum_to_chronological", true, "Total de Horas (Pedagógicas + Cronológicas)" ))+" ")]):_vm._e(),(_vm.school && _vm.school.internal_use_id != 'ciisa_uss')?_c('th',{staticClass:"header-row-rotated",attrs:{"title":_vm.$getVisibleNames('manual.credits', true, 'Créditos SCT Totales')}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("manual.credits", true, "Créditos SCT Totales"))+" ")]):_vm._e()],2)]),(_vm.egressProfile)?_c('tbody',_vm._l((_vm.egressProfile.semester_amount),function(semester){return _c('SummarySemesterRow',{key:semester,attrs:{"egress_profile_id":_vm.egress_profile_id,"egress_profile_time_allocations":_vm.egress_profile_time_allocations,"semester":semester}})}),1):_vm._e(),_c('tfoot',[(_vm.coursesWrapperMaxCredits > 0)?_c('tr',[_c('td',{staticClass:"font-weight-normal"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive.hover",value:(
              _vm.$getVisibleNames(
                'mesh.coursewrapper',
                true,
                'Envoltorios de Asignaturas'
              ).toUpperCase()
            ),expression:"\n              $getVisibleNames(\n                'mesh.coursewrapper',\n                true,\n                'Envoltorios de Asignaturas'\n              ).toUpperCase()\n            ",modifiers:{"v-secondary":true,"noninteractive":true,"hover":true}}],staticClass:"text-break",on:{"click":(e) => e.target.classList.toggle('text-break')}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.coursewrapper", true, "Envoltorios de Asignaturas" ).toUpperCase())+" ")])]),_vm._l((_vm.time_allocations.filter(
            (x) =>
              x.uses_module_minutes == true &&
              _vm.egress_profile_time_allocations.includes(x.id)
          )),function(hour){return _c('td',{key:hour.id})}),_c('td'),_c('td'),_vm._l((_vm.time_allocations.filter(
            (x) =>
              x.uses_module_minutes == false &&
              _vm.egress_profile_time_allocations.includes(x.id)
          )),function(hour){return _c('td',{key:hour.id})}),(_vm.school && _vm.school.internal_use_id != 'ciisa_uss')?_c('td'):_vm._e(),(_vm.school && _vm.school.internal_use_id != 'ciisa_uss')?_c('td'):_vm._e(),(_vm.school && _vm.school.internal_use_id != 'ciisa_uss')?_c('td',{staticClass:"font-weight-normal"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
              'Máximo de Créditos sugeridos.'
            ),expression:"\n              'Máximo de Créditos sugeridos.'\n            ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}]},[_vm._v(" "+_vm._s(_vm.coursesWrapperMaxCredits)+" ")])]):_vm._e()],2):_vm._e(),_c('tr',[_c('td',[_vm._v("TOTAL")]),_vm._l((_vm.time_allocations.filter(
            (x) =>
              x.uses_module_minutes == true &&
              _vm.egress_profile_time_allocations.includes(x.id)
          )),function(hour){return _c('td',{key:hour.id},[_vm._v(" "+_vm._s(_vm.countTimeAllocationHours(hour.id))+" ")])}),_c('td',{class:_vm.limit_study_plan &&
            _vm.limit_study_plan.max_hours != 0 &&
            _vm.limit_study_plan.max_hours < _vm.totalPedagogical
              ? 'limit-class'
              : ''},[_vm._v(" "+_vm._s(_vm.totalPedagogical)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.totalPedagogicalToChronological)+" ")]),_vm._l((_vm.time_allocations.filter(
            (x) =>
              x.uses_module_minutes == false &&
              _vm.egress_profile_time_allocations.includes(x.id)
          )),function(hour){return _c('td',{key:hour.id},[_vm._v(" "+_vm._s(_vm.countTimeAllocationHours(hour.id))+" ")])}),(_vm.school && _vm.school.internal_use_id != 'ciisa_uss')?_c('td',[_vm._v(" "+_vm._s(_vm.totalChronological)+" ")]):_vm._e(),(_vm.school && _vm.school.internal_use_id != 'ciisa_uss')?_c('td',[_vm._v(" "+_vm._s(_vm.totalHours)+" ")]):_vm._e(),(_vm.school && _vm.school.internal_use_id != 'ciisa_uss')?_c('td',[_c('span',{class:_vm.limit_study_plan &&
              _vm.limit_study_plan.max_credits != 0 &&
              _vm.limit_study_plan.max_credits < _vm.totalCredits
                ? 'limit-class'
                : ''},[_vm._v(_vm._s(_vm.totalCredits))])]):_vm._e()],2),(
          _vm.limit_study_plan &&
          (_vm.limit_study_plan.max_hours != 0 ||
            _vm.limit_study_plan.max_credits != 0)
        )?_c('tr',{directives:[{name:"can",rawName:"v-can",value:('mesh.change_egressprofile'),expression:"'mesh.change_egressprofile'"}]},[_c('td',[_vm._v("TOTAL SUGERIDO")]),_vm._l((_vm.time_allocations.filter(
            (x) =>
              x.uses_module_minutes == true &&
              _vm.egress_profile_time_allocations.includes(x.id)
          )),function(hour){return _c('td',{key:hour.id})}),_c('td',[(_vm.limit_study_plan && _vm.limit_study_plan.max_hours != 0)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
              'Máximo de Módulos sugerido.'
            ),expression:"\n              'Máximo de Módulos sugerido.'\n            ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}]},[_vm._v(_vm._s(_vm.limit_study_plan.max_hours))]):_c('span',[_vm._v("-")])]),_c('td'),_vm._l((_vm.time_allocations.filter(
            (x) =>
              x.uses_module_minutes == false &&
              _vm.egress_profile_time_allocations.includes(x.id)
          )),function(hour){return _c('td',{key:hour.id})}),_c('td'),_c('td'),_c('td',[(_vm.limit_study_plan && _vm.limit_study_plan.max_credits != 0)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
              'Máximo de Créditos sugeridos.'
            ),expression:"\n              'Máximo de Créditos sugeridos.'\n            ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}]},[_vm._v(_vm._s(_vm.limit_study_plan.max_credits))]):_c('span',[_vm._v("-")])])],2):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
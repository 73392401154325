var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v(_vm._s(_vm.numberToWord(_vm.semester)))]),_vm._l((_vm.time_allocations.filter(
      (x) =>
        x.uses_module_minutes == true &&
        _vm.egress_profile_time_allocations.includes(x.id)
    )),function(hour){return _c('td',{key:hour.id},[_vm._v(" "+_vm._s(_vm.countTimeAllocationHours(hour.id))+" ")])}),_c('td',[_vm._v(_vm._s(_vm.totalPedagogical))]),_c('td',[_vm._v(" "+_vm._s(_vm.totalPedagogicalToChronological)+" ")]),_vm._l((_vm.time_allocations.filter(
      (x) =>
        x.uses_module_minutes == false &&
        _vm.egress_profile_time_allocations.includes(x.id)
    )),function(hour){return _c('td',{key:hour.id},[_vm._v(" "+_vm._s(_vm.countTimeAllocationHours(hour.id))+" ")])}),(_vm.school && _vm.school.internal_use_id != 'ciisa_uss')?_c('td',[_vm._v(" "+_vm._s(_vm.totalChronological)+" ")]):_vm._e(),(_vm.school && _vm.school.internal_use_id != 'ciisa_uss')?_c('td',[_vm._v(" "+_vm._s(_vm.totalHours)+" ")]):_vm._e(),(_vm.school && _vm.school.internal_use_id != 'ciisa_uss')?_c('td',[(_vm.profileType)?_c('div',[_vm._v(" "+_vm._s(_vm.totalSCT)+" ")]):_vm._e()]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }